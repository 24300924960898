.editAvatarIcon {
	border-radius: 20px;
	color: rgb(255 255 255);
	border: solid 1px rgb(255 255 255);
	font-size: 90%;
	top: auto !important;
	right: auto !important;
	bottom: 6px;
	left: 31px;
	transform: translate(0) !important;
	background-color: rgb(0 0 0 / 33%);
	display: inline-block;
	width: 65px;
}

@primary-color: #fe22c2;@link-color: #3171f5;@layout-header-background: #ffffff;