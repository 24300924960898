@import '~antd/dist/antd.less';

#logo {
  float: left;
  width: 80px;

  #logo-img {
    height: 45px;
  }
}
.full-width {
  width:  100%;
}
.site-card-wrapper {
  margin-top: 50px;
}
.card-box-shadow {
  box-shadow: 0px 5px 18px -5px rgb(0 0 0 / 10%);
}

.ant-card.menu-card {
  transition: box-shadow .4s;
  transition-delay: .2s;
  border-radius: 10px;
}
.ant-card.menu-card.active, .ant-card.menu-card:hover {
  box-shadow: 0px 5px 18px -5px rgb(0 0 0 / 10%);
}
.ant-result.ant-result-info {
  .ant-result-subtitle {
    min-height: 68px;
  }
}
.spaceCenter {
  .ant-space-item {
    width: 100%;
    margin: auto;
  }
}
.width100 {
  width: 100%;
}
/* OVERWRITE ANTD */
.site-layout .site-layout-background {
  background: #fff;
}
.ant-layout {
  min-height: 100vh;
}
.ant-layout-header {
  backdrop-filter: saturate(180%) blur(20px);
	background: rgb(255 255 255 / 80%);
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
  .ant-menu-item a {
    color: rgb(0 0 0 / 85%);
  }
  .header-right {
    float: right;
  }
}
.ant-layout-content {
  .site-layout-background {
    box-shadow: 0px 5px 18px -5px rgb(0 0 0 / 10%);
    border-radius: 9px;
  }
}
.ant-layout-footer {
  margin-top: 60px;
}
/* END OVERWRITE ANTD */

.text-center {
  text-align: center;
}

.avatar-uploader:hover {
  cursor: pointer;
  opacity: .8;
  .ant-avatar {
    box-shadow: 0 4px 8px 0px rgb(0 0 0 / 10%);
  }
}
.site-form-item-icon {
  margin-right: 5px;
}
.ant-card-body .digix-card-title {
  margin-bottom: 20px;
  letter-spacing: 0.5px;
}
.ant-table-thead > tr > th {
  font-weight: 700;
}
@primary-color: #fe22c2;@link-color: #3171f5;@layout-header-background: #ffffff;